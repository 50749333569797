/**
 * The notary portal was removed from the product with the Proof rebrand in late 2023.
 *
 * This module exists for some backwards compat and attempts to redirect user to
 * useful/equivalent features in other portals for old email links or stale bookmarks.
 */
import AppSubdomains from "constants/app_subdomains";
import { redirectToSubdomain } from "util/application_redirect";

type RedirectToParams = Parameters<typeof redirectToSubdomain>;
type Redirect = [subdomain: RedirectToParams[0], path: string];
type Route = Readonly<{
  path: string;
  redirect: (options: { pathname: string; search: string }) => Redirect;
}>;

const ROUTES: Route[] = [
  {
    path: "/login",
    redirect: () => [AppSubdomains.customer, "/login"],
  },
  {
    path: "/reset_password/",
    redirect: ({ pathname }) => [AppSubdomains.customer, pathname],
  },
  {
    path: "/activate/",
    redirect: ({ pathname }) => [AppSubdomains.customer, pathname],
  },
  {
    path: "/verify-account",
    redirect: ({ pathname, search }) => [AppSubdomains.customer, `${pathname}${search}`],
  },

  {
    path: "/signup",
    redirect: () => [AppSubdomains.business, "/signup/notary_basic"],
  },

  {
    path: "/onboarding",
    redirect: ({ pathname }) => {
      const [, , step] = pathname.split("/");
      const basePath = "/settings/notary/onboarding";
      return [AppSubdomains.customer, step ? `${basePath}/${step}` : basePath];
    },
  },

  {
    // Will also handle /journal/download-export/:id
    path: "/journal",
    redirect: ({ pathname }) => [AppSubdomains.customer, pathname],
  },
];

function findRedirectRoute(): Redirect {
  const { pathname, search } = window.location;
  const firstMatchingRoute = ROUTES.find((route) => pathname.startsWith(route.path));
  return (
    firstMatchingRoute?.redirect({ pathname, search }) || [
      // If we don't know where to go, default send them to portal-gateway which has logic
      // for sending user to different kinds of organization portals.
      AppSubdomains.customer,
      "/portal-gateway",
    ]
  );
}

redirectToSubdomain(...findRedirectRoute());
